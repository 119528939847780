//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getequipmentOrderList } from "@/api/maintenance.js";
import {_equipmentApplyHistroy,_getequipmentOrderDelete,_getequipmentApproval} from "@/api/disabilities.js"
import ChangeApplication from "./ChangeApplication.vue";
import { _getcode } from "@/api/organizational";
import Approval from "./approval.vue";
import EditChange from "./editChange.vue"
export default {
  components: {
    ChangeApplication,
    Approval,
    EditChange,
  },
  data() {
    return {
     pageSize:10,//没页几条
      current: 1,//当前页数
      total:0,//总条数
      HistroyData:[],
      types: "",
      formInline: {
        orderId: "",
        eaStatusCode: "",
      },
      options: [],
      value: "",
      input: "",
      tableData: [],
      registerType:""
    };
  },
  created() {
    this.getequipmentOrderList({current:this.current, page:this.pageSize});
    this.types = JSON.parse(localStorage.getItem("roleType"));
      this.registerType = JSON.parse(localStorage.getItem("registerType"));
    this.getcode();
  },
  methods: {
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:60px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0";
      }
    },
    // 列表数据接口
    getequipmentOrderList(from) {
      _getequipmentOrderList(from).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    //申请状态的下拉数据
    getcode(){
       let from = {
        para: "sqzt",
      };
      _getcode(from).then((res)=>{
         this.options = res.data;
      })
    },
    // 新增的事件
    confirm() {
      this.$refs.changeApplication.open("新增");
    },
    // 修改的事件
    handleClick(row) {
      this.$refs.editChange.open(JSON.parse(JSON.stringify(row))
      );
    },
    // 审批事件
    approval(row) {
      if(row.exist == 1 && this.registerType == "2"){
         this.$confirm('是否确定审核通过', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _getequipmentApproval({id:row.id,tf:'1'}).then(()=>{
            this.getequipmentOrderList({current:this.current, page:this.pageSize,...this.formInline})
          })
        })
      }else{
      this.$refs.approval.open(JSON.parse(JSON.stringify(row.id)));
      }
    },
    clickTable(row,index,e){
      //  _equipmentApplyHistroy({ id: row.id }).then((res) => {
      //   this.HistroyData = res.data
      //   this.$refs.refTable.toggleRowExpansion(row)
      // });
    },
    // 查询
    query() {
      this.getequipmentOrderList(this.formInline)
    },
        // 撤销事件
    delBtn(row){
       this.$confirm('是否确认撤销', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _getequipmentOrderDelete({id:row.id}).then((res)=>{
          if(res.code == "1"){
            this.$message(res.msg)
           this.getequipmentOrderList({current:this.current, page:this.pageSize,...this.formInline})
          }
      })
        })
    },
    // 分页
    handleSizeChange(val) {
       this.current = 1
      this.pageSize = val
       let from = {
        current:this.current,
        page:this.pageSize
        }
      this.getequipmentOrderList(from)
    },
    handleCurrentChange(val) {
       this.current = val
      let from = {
        current:this.current,
        page:this.pageSize,
        }
        this.getequipmentOrderList(from)
    },
  },
};
