//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  _getmtorderSearch,
  _getequipmentSearch,
  _getequipmentOrder,
  _getequipmentSearchId,
  _getequipmentOrderEdit
} from "@/api/disabilities.js";
export default {
  data() {
    return {
      title: "",
      dialogVisible: false,
      options: [],
      disabled: false,
      optionsName: [],
      btnDisab:false,
      form: {
        applyStatus: "", //申请类型
        name: "", //设备名称
        id:"",
        moId: "",//工单号id
        orderId: "", //工单号的名称
        type: "", //设备类型
        money: "",
        applyOp: "",
        opComment: "",
      },
            rules:{
          name: [
            { required: true, message: '请选择设备名称', trigger: 'change' }
          ],
           type: [
            { required: true, message: '请输入设备型号', trigger: 'blur' }
          ],
           money: [
            { required: true, message: '请输入设备金额', trigger: 'blur' },
          ],
           applyOp: [
            { required: true, message: '请输入申请原因' ,trigger: 'blur'},
          ],
      }
    };
  },
  methods: {
    open(data) {
      this.dialogVisible = true;
        this.form = Object.assign(data);
    },
    
    //工单设备的筛选事件
    querySearch(queryString, cb) {
      _getequipmentSearch({ name: queryString }).then((res) => {
       this.optionsName = res.data.map((item)=>{
         item.value = item.name+'('+item.firm+")"
         return {
           ...item,
         }
         })
        let newData = this.optionsName
         let results = queryString
        ? newData.filter(this.createFilter(queryString))
        : newData;
      cb(results);
      });
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase()
        );
      };
    },
    handleSelect(item) {
      this.form.name = item.name;//工单设备名称
      this.form.type = item.type;
      this.form.money = item.price;
    },
    // 提交
    onSubmit() {
       this.$refs["form"].validate((valid) => {
          if (valid) {
            this.btnDisab = true
           this.form.money = parseFloat(this.form.money)
              _getequipmentOrderEdit(this.form).then((res) => {
              if (res.code == "1") {
                this.dialogVisible = false;
                this.$message(res.msg);
                this.$emit("query");
                this.btnDisab = false
              }else{
                this.btnDisab = false
              }
            }).catch(()=>{
              this.btnDisab = false
            });
          }
       })
      
    },
    close() {
      this.form = {
         moId: "",//工单号id
            orderId: "",//工单号的名称
            applyStatus: "2",
            name: "",//设备名称
            id: "",//设备id
            type: "",
            money: "",
            applyOp: "",
            opComment: "",
      };
      this.options = [];
      this.$refs["form"].resetFields();
    },
  },
};
